/**
 * EscanerPage
 * Realiza todo el proceso de captura y validación de datos.
 * 
 * TODO:
 *  - Limpiar este componente y atomizarlo... PORFAVOR!!.
 *  - Detener todos los audios antes de reproducir uno nuevo.
 * 
 * @author Fabián Vásquez - Francisco Miranda
 * Última revisión 20/02/2021
*/

/** Librerias necesarias para el funcionamiento */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import QrReader from 'react-qr-reader'
import useSound from 'use-sound'
import { Redirect, useHistory } from 'react-router-dom'


/** Componente locales */
import AppShell from '../../components/AppShell/AppShell'                           //  Layout de la app (incluye header y footer)
import Notificacion from './Notificacion'                                           //  Area de notificación para lector con cámara
import DetalleEscaneo from './DetalleEscaneo'                                       //  Info escaneada <- Utilizar como ejemplo para atomizar más componentes.

/** Elementos estaticos de la vista */
import pasajeroEmbarcado from '../../assets/audio/pasajero_embarcado.mp3'
import vehiculoEmbarcado from '../../assets/audio/vehiculo_embarcado.mp3'
// import ticketNoValido from '../../assets/audio/ticket_no_valido.mp3'
import ticketNoValido from '../../assets/audio/ticket_repetido.mp3'
import ticketRepetido from '../../assets/audio/ticket_repetido.mp3'


export default function EscanerPage() {

    /** variables globales y constantes  */
    const history = useHistory()

    /** Audios de eventos */
    const [playEmbarcado] = useSound(pasajeroEmbarcado)
    const [playVehiculoEmbarcado] = useSound(vehiculoEmbarcado)
    const [playError] = useSound(ticketNoValido)
    const [playRepetido] = useSound(ticketRepetido)

    /** Tipo de capturador: camara - laser */
    const [tipoCamara, setTipoCamara] = useState(localStorage.getItem('tipoCamara') ?? 'camara')

    /** Lectura con laser */
    const [laser, setLaser] = useState()

    /** estado local para esta vista. */
    const [state, setState] = useState(JSON.parse(localStorage.getItem('estado')))

    /** Manejo de la notificacion del estado del ticket {estado: string, mensaje: string} */
    const [mensajeNotificacion, setMensajeNotificion] = useState([])

    /** total de tickets escanedos */
    const [escaneados, setEscaneados] = useState(state.escaneados ?? [])

    /** pasajeros embarcados */
    const [personasEmbarcadas, setPersonasEmbarcados] = useState(state.personasEmbarcadas ? state.personasEmbarcadas : 0)

    /** pasajeros embarcados */
    const [vehiculosEmbarcados, setVehiculosEmbarcados] = useState(state.vehiculosEmbarcados ? state.vehiculosEmbarcados : 0)


    /** Actualiza el estado almacenado en localStorage con el estado de la App 
     * 
     * TOODO:
     *  - Agregar timestamps a la ultima carga de estado en localStorage para actualizar 
     *     segun el tiempo transcurrido por seguridad.
    */
    const actualizarLocalstorage = () => {
        console.log('actualizando LS')
        console.log(JSON.parse(localStorage.getItem('estado')))
        state.personasEmbarcadas = personasEmbarcadas
        state.vehiculosEmbarcados = vehiculosEmbarcados
        state.escaneados = escaneados
        localStorage.setItem('estado', JSON.stringify(state))
    }


    /** configuración inicial al cargar la vista. */
    useEffect(() => {
        if (!localStorage.getItem('estado')) {
            history.push('/')
        }

        if (!tipoCamara) {
            console.log("!tipoCamara")
            localStorage.setItem('tipoCamara', 'camara')
        }
    }, [history])



    // validamos que exista un cruce en el estado, o volvemos a la pagina de seleccion...
    useEffect(() => {

        //! validar si trae mas de un cruce, para el caso del tramo de Puerto natales

        if (state) {
            if (!state.cruceSeleccionado) {
                history.push('/seleccionar-cruce')
            } return
        } else {
            if (!state.cruceSeleccionado) {
                history.push('/seleccionar-cruce')
            } return
        }
    }, [state, setState, history])


    // Manejo de reinicio de notificación.
    useEffect(() => {
        if (mensajeNotificacion.length > 0) {
            setTimeout(function () { setMensajeNotificion([]) }, 2000)
            actualizarLocalstorage()
        }
    }, [mensajeNotificacion, setMensajeNotificion])

    

    // variable para almacenar las partes del codigo escaneado y armar la cadena completa.
    let text = ''

    // que hacer cuando se presiona una tecla en este documento...
    const handleKeyDown = (e) => {
        if (e.key !== 'Unidentified' && e.key !== 'Shift' && e.key !== 'z') {
            text += e.key
        }
        // Z es el caracter que finaliza el codigo QR del ticket. PORQUE SI
        if (e.key === 'z') {
            setLaser(text)
            text = ''
        }
    }

    /** Con esto agregamos un eventListener para capturar las teclas presionadas y armar el código */
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [])


    const scan = async (data) => {
        // console.log(data)
        // no hay datos, adios
        if (data === null) return

        // veamos que no exista primero
        // if (escaneados.indexOf(data) !== -1) {
        //     playRepetido()
        //     setMensajeNotificion([{ estado: 'error', mensaje: 'Código ya escaneado' }])
        //     actualizarLocalstorage()
        //     return
        // }
        console.log('index of: ' + escaneados.indexOf(data) !== -1)

        // validar el formato
        const ticket = data.split('&')

        if (ticket.length > 1) {
            const ticketQuery = {
                tipo: ticket[0],                                    // auto o persona
                id_ticket: ticket[1],
                id_cruce: state.cruceSeleccionado.id_cruce,
                id_tramo: state.cruceSeleccionado.id_tramo,
                val_seed: ticket[3]
            }

            /** quizas esto quitarlo a una funcion completa aparte... */
            console.log('ticketQuery')
            console.log(ticketQuery)
            return embarcarTicket(ticketQuery, data)
        } else {
            return
        }
    }


    useEffect(() => {
        if (laser) {
            scan(laser)
        } else {
            setLaser()
        }
    }, [laser, setLaser])

    const scanError = (error) => {
        console.log(error)
    }



    const embarcarTicket = async (ticket, scanData) => {
        // hay que agregarle el id del usuario
        ticket.id_usuario = 1; // esto deberia ser un usuario real para poder trackear
    
        const url = 'https://api-cruce.dev.tabsa.cl/api/validarTickets';
        console.log('validar tickets');
    
        console.log('ticket', ticket);
    
        try {
            const response = await fetch(url, { method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(ticket) });
            const data = await response.json();
    
            console.log('validar tickets 2', JSON.stringify(data));
    
            if (data[0].codigo == 1) {
                // OK -> embarcar
                if (parseInt(ticket.tipo) == 1) {
                    setPersonasEmbarcados(prevCount => prevCount + 1);
                    setMensajeNotificion([{ estado: 'ok', mensaje: 'Pasajero embarcado' }]);
                    actualizarLocalstorage();
                    playEmbarcado();
                } else {
                    setVehiculosEmbarcados(prevCount => prevCount + 1);
                    setMensajeNotificion([{ estado: 'ok', mensaje: 'Vehículo embarcado' }]);
                    actualizarLocalstorage();
                    playVehiculoEmbarcado();
                }
            } else {
                playError();
                setMensajeNotificion([{ estado: 'error', mensaje: data[0].mensaje }]);
            }
    
            setEscaneados(prevEscaneados => [...prevEscaneados, scanData]);
            console.log('escaneados', escaneados);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    

    /** Parche para reacargar la camara manualmente */
    const recargarCamara = () => {
        toast.info('Despertando cámara...')
        return <Redirect to="/escaner" />
    }

    return (
        <AppShell titulo="Embarque" capturador={setTipoCamara} >
            <div className="w-full h-full flex flex-col overflow-y-hidden">
                <div className="w-full overflow-none">

                    {tipoCamara !== 'laser' &&
                        <div className="w-full shadow-lg">
                            <div className="flex w-full justify-center bg-gray-800 h-auto">
                                <QrReader
                                    delay={1000}
                                    onError={scanError}
                                    onScan={scan}
                                    style={{ width: 200 }}
                                />
                            </div>

                            <Notificacion tipo={mensajeNotificacion.length > 0 ? mensajeNotificacion[0].estado : 'none'}>
                                {mensajeNotificacion.length > 0 ? mensajeNotificacion[0].mensaje : 'esperando lectura...'}
                            </Notificacion>

                            <div className="py-3 w-full">
                                <button onClick={recargarCamara} className="block bg-yellow-600 w-11/12 mx-auto rounded text-white text-lg font-semibold py-2">Recargar Cámara</button>
                            </div>
                        </div>
                    }

                    {tipoCamara === 'laser' &&
                        <div className="w-full">
                            <div className="mb-0 shadow-xl relative w-full mx-auto font-black text-2xl border-l-2 border-r-2 border-b-2 border-gray-900 bg-gray-800 py-4 text-yellow-500 px-4">
                                <div className="w-full absolute opacity-50 top-0.5 left-0 bg-gradient-to-b from-white h-1/2"></div>

                                <div className="z-10" style={{ textShadow: '0px 0px 10px rgba(230,130,20,1)', fontFamily: "'VT323', monospace" }}>
                                    {mensajeNotificacion.length > 0 ? mensajeNotificacion[0].mensaje : "Esperando datos..."}
                                </div>

                            </div>
                        </div>
                    }

                </div>

                <div className="w-full h-full overflow-y-auto">
                    <DetalleEscaneo escaneados={escaneados} personasEmbarcadas={personasEmbarcadas} vehiculosEmbarcados={vehiculosEmbarcados} />
                </div>
            </div>

        </AppShell>
    )
}
